const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "indexNumber",
  },
  {
    title: "学校名称",
    align: "center",
    dataIndex: "schoolName",
  },
  {
    title: "安全负责人",
    align: "center",
    dataIndex: "securityOfficer",
  },
  {
    title: "联系方式",
    align: "center",
    dataIndex: "phone",
  },
];
export { columns };
