<template>
  <div style="background: white; padding: 20px">
    <a-form-model
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      layout="inline"
    >
      <a-form-model-item label="学校搜索">
        <a-input
          v-model="form.schoolName"
          placeholder="请输入"
          allowClear
          @keyup.enter="searchList"
        />
      </a-form-model-item>
      <a-form-model-item label="负责人搜索">
        <a-input
          v-model="form.securityOfficer"
          placeholder="请输入"
          allowClear
          @keyup.enter="searchList"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="searchList"> 查询 </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="reset">重置</a-button>
      </a-form-model-item>
    </a-form-model>
    <a-table
      rowKey="indexNumber"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="tableLoading"
      size="middle"
    >
    </a-table>
  </div>
</template>

<script>
import { columns } from "./columns";
import * as api from "@/api/addressBook.js";
export default {
  name: "addressBookList",
  props: {},
  components: {},
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      form: {
        schoolName: "",
        securityOfficer: "",
      },
      columns,
      tableLoading: false,
      dataSource: [],
      pagination: {
        current: 1,
        showTotal: (total, range) => `第${range.join("-")}条/总共${total}条`,
        onChange: (page, pageSize) => this.changePage(page, pageSize),
        onShowSizeChange: (current, size) => this.showSizeChange(current, size),
        total: 0,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
      },
    };
  },
  methods: {
    changePage(page, pageSize) {
      this.pagination.current = page;
      this.getList();
    },
    showSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getList();
    },
    getList() {
      // console.log("getList");
      this.tableLoading = true;
      let _data = {
        ...this.form,
        page: this.pagination.current.toString(),
        pageSize: this.pagination.pageSize.toString(),
      };
      api
        .getPhoneList(_data)
        .then((res) => {
          console.log(res);
          this.tableLoading = false;
          this.pagination.total = parseInt(res.data.data.total);
          this.dataSource = res.data.data.records.map((item, index) => {
            return {
              ...item,
              indexNumber:
                (this.pagination.current - 1) * this.pagination.pageSize +
                index +
                1,
            };
          });
        })
        .catch((res) => {
          this.tableLoading = false;
          this.openNotification("error", res.data.msg);
        });
    },
    searchList() {
      this.pagination.current = 1;
      this.getList();
    },
    reset() {
      this.form = {
        schoolName: "",
        securityOfficer: "",
      };
      this.searchList();
    },
    //提示
    openNotification: function (type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped></style>
